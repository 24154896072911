import React, { useState } from 'react';
import {
    AboutContainer,
    AboutWrapper,
    AboutRow,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    ImgWrapLogo,
    VideoBg
} from '../Mission/MissionElements'

import { Button } from '../../Utilities/ButtonElements';

import styled from 'styled-components';

const Contracting = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    id,
    primary,
    dark,
    dark2
}) => {

    const [isVisible, setIsVisible] = useState(false);

    const toggleList = () => {
        setIsVisible(!isVisible);
    };

    const ServicesContainer = styled.div`
    border-radius: 10px;
    max-width: 600px; /* Set max width for the container */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    
`;

    const ServicesList = styled.ul`
    list-style-type: none; /* Removes default bullet points */
    padding-left: 0; /* Remove padding */
    margin: 0; /* Remove default margin */
`;

    const ListItem = styled.li`
    margin-bottom: 10px; /* Space between list items */
    font-size: 16px;
    color: #fff; /* Dark text color */
`;

    const ExploreButton = styled.button`
    background-color: #01bf71; /* Green background */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding for button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px;
    margin-bottom: 20px; /* Spacing below the button */
    transition: background 0.3s ease; /* Smooth background change */

    &:hover {
        background-color: #019f5d; /* Darker green on hover */
    }
`;



    return (
        <>
            <AboutContainer lightBg={lightBg} id='contracting'>
                <AboutWrapper>
                    <AboutRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <p>SAM Unique Entity ID: NMT6NVKQJB36</p>
                                <p> CAGE/NCAGE Code: 8PE57</p>
                                <p>NAICS Codes: 518210,
                                    541511,
                                    541512,
                                    541513,
                                    541519,
                                    541611,
                                    541715,
                                    541330
                                    </p>

                                <TopLine>Key Capabilities</TopLine>

                                <ServicesContainer>
                                    {/* <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Key Capabilities</h2> */}
                                    <ExploreButton onClick={toggleList}>
                                        {isVisible ? 'Hide Services' : 'Explore Services'}
                                    </ExploreButton>
                                    {isVisible && (
                                        <ServicesList>
                                            <ListItem>AI & ML</ListItem>
                                            <ListItem>Agile Software Development</ListItem>
                                            <ListItem>APIs & Microservices Design</ListItem>
                                            <ListItem>IT Modernization</ListItem>
                                            <ListItem>DevOps & Platform Engineering</ListItem>
                                            <ListItem>IOT & Data Engineering</ListItem>
                                            <ListItem>Data & Analytics</ListItem>
                                            {/* <ListItem>Product Management</ListItem> */}
                                        </ServicesList>
                                    )}
                                </ServicesContainer>
                            </TextWrapper>
                        </Column1>
                    </AboutRow>
                </AboutWrapper>
            </AboutContainer>
        </>
    )
}

export default Contracting
