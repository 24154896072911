import styled from 'styled-components';

export const ServicesContainer = styled.div`
    height: auto; // Let height be auto for adaptive sizing
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #010606;
    padding: 40px 0; // Added padding for top and bottom spacing

    @media screen and (max-width: 768px) {
        padding: 20px 0; // Reduced padding for medium screens
    }

    @media screen and (max-width: 480px) {
        padding: 10px 0; // Further reduced padding for mobile
    }
`;

export const ServicesWrapper = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Using repeat for consistency
    align-items: center;
    gap: 16px; // Changed grid-gap to gap for better readability
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        max-width: 800px; // Adjusted max-width for better layout
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px; // Reduced padding for smaller screens
    }
`;

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    max-height: 465px;
    padding: 45px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;


    @media screen and (max-width: 480px) {
        max-height: 400px; // Reduced max-height for mobile
        padding: 30px; // Adjust padding for mobile
    }
`;

export const ServicesIcon = styled.img`
    margin-top: -30px;
    height: 80px;
    width: 80px;
    margin-bottom: 10px;

    @media screen and (max-width: 480px) {
        height: 60px; // Reduced icon size for mobile
        width: 60px;
    }
`;

export const ServicesH1 = styled.h1`
    margin-top: 30px;
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 35px;
    text-align: center;

    @media screen and (max-width: 580px) {
        font-size: 2rem; // Adjusted font size for mobile
        margin-bottom: 20px; // Reduced margin
        margin-top: 400px
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
    filter: blur(4px);
`;

export const ServicesH2Reg = styled.h2`
    margin-top: -15px;
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: center;
`;

export const ServicesP = styled.p`
    font-size: 0.76rem;
    text-align: center;
    margin-bottom: 10px;
    color: black;

    @media screen and (max-width: 480px) {
        font-size: 0.65rem; // Font size remains unchanged but can be adjusted if needed
    }
`;


