import styled from 'styled-components'

export const AboutContainer = styled.div`
color: #fff;
background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};

@media screen and (max-width:568px) {
    padding: 100px 0;
}
`

export const AboutWrapper = styled.div`
display: grid;
z-index: 1;
height: 490px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
justify-content: center;

`

export const AboutRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width:768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`

export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;


`

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`

export const TextWrapper = styled.div`
max-width: 640px;
padding-top: 0;
padding-bottom: 60px;
`

export const TopLine = styled.p`
color: #01bf71;
font-size: 18px;
line-height:16px;
font-weight: 800;
letter-spacing: 1.px;
text-transform: uppercase;
margin-bottom: 16px;
margin-top: 30px;

@media screen and (max-width:568px) {
margin-top: 30px;
}
`
export const Heading = styled.h1`
color: #000;
margin-bottom: 24px;
font-size: 22px;
line-height:1.2;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};


@media screen and (max-width:480px) {
    font-size: 25px;
}
`

export const Subtitle = styled.p`
max-width: 440px;
margin-bottm: 35px;
font-size: 16px;
line-height: 24px;
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`

export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`

export const ImgWrapLogo = styled.div`
max-width: 555px;
height: 100%;

`

export const Img = styled.img`
width: 100%;
padding-right: 0;
margin-left: 80px;

@media screen and (max-width:580px) {
margin-left: 0px;

}

`
export const VideoBg = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;

`

export const CompanyDataContainer = styled.div`
    font-family: Arial, sans-serif;
    margin: 20px;
    line-height: 1.6;
`;

export const CompanyDataHeading = styled.h2`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px; 
`;

export const CompanyList = styled.ul`
    list-style-type: none; /* Removes default bullet points */
    padding-left: 0; /* Removes padding */
`;

export const CompanyListItem = styled.li`
    margin-bottom: 5px; /* Space between list items */
`;
