
export const homeObj1 = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    description: 'PZMOO was launched in February 2018. Our office is located in Washington, DC. We operate as a distributed and remote-friendly team. Our journey began as a consulting company focused on solving challenging problems and building scalable applications. Over time, we have strategically expanded our focus to encompass the development of AI-driven startups that effectively tackle various challenges and provide meaningful value to our customers.',
    imgStart: false,
    img: require('../../Images/about.gif').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}


export const homeObj2 = {
    id: 'mission ',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mission',
    description: 'Our mission is to lead the charge in technological innovation by developing next-generation AI-driven applications at scale. We aim to transform visionary ideas into impactful, real-world solutions that drive efficiency, enhance capabilities, and create a smarter, more connected future. Through relentless dedication to excellence and cutting-edge technology, we strive to empower individuals and organizations to reach new heights of success and innovation.',
    buttonLabel: 'Services',
    imgStart: false,
    img: require('../../Images/img5.png').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}


export const homeObj5 = {
    id: 'contracting ',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Contracting',
    description: 'Our mission is to lead the charge in technological innovation by developing next-generation AI-driven applications at scale. We aim to transform visionary ideas into impactful, real-world solutions that drive efficiency, enhance capabilities, and create a smarter, more connected future. Through relentless dedication to excellence and cutting-edge technology, we strive to empower individuals and organizations to reach new heights of success and innovation.',
    buttonLabel: 'Services',
    imgStart: false,
    img: require('../../Images/img5.png').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}



export const homeObj3 = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact Us',
    headline: 'Building the Future Together!',
    description: 'PZMOO is currently operating as a fully bootstrapped venture. However, the future is full of possibilities! If you are interested in following our journey and exploring potential acquisitions or investments, we would be thrilled to stay in touch!',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../Images/Account2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
}